@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul{
  list-style: none;
  padding: 0;
}

p{
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
    --primary: #E9E9EB;
    --secondary: #43506C;
    --third: #FFD850;
    --fourth: #C4C4C4;
    --fifth: #3D619B;
    --sixth: #514D4D;
    --seventh: #EF4B4C;
    --eighth: #ABA9A9;
    --ninth: #f7f7f7;
}
.App {
  background-color: var(--ninth);
  min-height: 100vh;
  font-family: 'Source Serif Pro', serif;
}


