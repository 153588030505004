:root{
    --primary: #E9E9EB;
    --secondary: #43506C;
    --third: #FFD850;
    --fourth: #C4C4C4;
    --fifth: #3D619B;
    --sixth: #514D4D;
    --seventh: #EF4B4C;
    --eighth: #ABA9A9;
    --ninth: #f7f7f7;
}